import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["button"]

  declare readonly buttonTarget: HTMLButtonElement | HTMLAnchorElement | HTMLInputElement;

  submit(event) {
    const button = this.buttonTarget;
    const message = button.dataset.confirmMessage;
    const confirmed = confirm(message);
    if (!confirmed) {
        event.preventDefault();
    } else {
      button.closest("form").submit();
      button.disabled = true;
    }
  }
}
