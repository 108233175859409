import { Application } from "@hotwired/stimulus"
import ConfirmController from "./controllers/confirm_controller"
import InputController from "./controllers/input_controller"
import ClipboardController from "./controllers/clipboard_controller"
import ContractController from "./controllers/admin/contract_controller"

const application = Application.start()

application.register("confirm", ConfirmController)
application.register("input", InputController)
application.register("clipboard", ClipboardController)
application.register("contract", ContractController)

export { application }

document.addEventListener("DOMContentLoaded", function() {
  document.querySelectorAll("input[type='submit'][data-disable-with]").forEach(function(button) {
    button.addEventListener("click", function() {
      const form = button.closest('form');
      if (form != null && !form.checkValidity()) {
        return; // フォームの必須項目が入力されていない場合はボタンを非活性にしない
      }

      setTimeout(() => { button.disabled = true; }, 0);
    });
  });
});
