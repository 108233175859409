import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["serviceType", "ssid", "companyName"];

  submit(event) {
    if (this.companyNameTarget.value.trim() === "") {
      event.preventDefault();
      alert("SSIDを入力して、会社検索して会社名を確認してください");
    }
  }

  clearCompanyName() {
    this.companyNameTarget.value = ""; // 会社名フィールドをクリア
  }

  get(event: Event) {
    event.preventDefault()

    const serviceType = this.serviceTypeTarget.value;
    const ssid = this.ssidTarget.value;
    const authenticityToken = document.querySelector('input[name="authenticity_token"]').value;

    if (!ssid) {
      alert("SSIDを入力してください。");
      return;
    }

    if (!serviceType) {
      alert("利用サービスを入力してください。");
      return;
    }

    const params = new URLSearchParams({
      authenticity_token: authenticityToken,
      service_type: serviceType,
      ssid: ssid,
    });

    fetch(`/admin/sa/company_name?${params.toString()}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },})
      .then((response) => {
        if (!response.ok) {
          // HTTPステータスコードが200系以外の場合
          alert("会社情報の取得に失敗しました。管理者にお問い合わせください。");
          return;
        }
        return response.json(); // 正常時のレスポンス
      })
      .then((data) => {
        const companyName = data["company_name"];
        if (companyName.length === 0) {
          alert("該当する会社が見つかりませんでした。");
          return;
        }
        this.companyNameTarget.value = companyName;
      })
  }
}
